import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
import 'swiper/css';
import 'swiper/css/pagination';
import './App.css';
import { Mousewheel } from 'swiper/modules';

export default function App() {
  const [data, setData] = useState();
  async function getAllVideos() {
    await axios.get(process.env.REACT_APP_BASE_URL + 'api/realvideo')
      .then((res) => {
        setData(res.data?.data)
      })
      .catch((error) => console.log("Error while getting data : ", error));
  }
  useEffect(() => {
    getAllVideos();
  }, []);

  return (
    <>
      <Swiper
        direction={'vertical'}
        mousewheel={true}
        className="mySwiper"
        modules={[Mousewheel]}
        loop={true}
        onSlideChange={() => {
          const videos = document.querySelectorAll('video');
          videos.forEach(video => {
            video.pause();
          });
        }}
      >
        {data && data.map((data, index) => (
          <div key={`vid-${index}`}>
            <SwiperSlide >
              <video className='videos' id={`video-${index}`} width="100%" height="100%" controls={false} autoPlay={true} loop
                onDrag={() => {
                  let videoObj = document.getElementById(`video-${index}`)
                  if (!videoObj.paused) {
                    videoObj.pause()
                  }
                }}
                onClick={() => {
                  let videoObj = document.getElementById(`video-${index}`)
                  if (videoObj.paused) {
                    videoObj.play()
                  } else {
                    videoObj.pause()
                  }
                }}
              >
                <source src={`${data.videoSDURL}`} type="video/mp4" />
              </video>
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </>
  );
}

